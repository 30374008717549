import React from 'react';
import Post from '../../components/post';
import LazyLoadImage from '../../components/LazyLoadImage';


const FamilyBudgetServicesReviewCreditCounselingPage = () => {
    const article = {
        id: 'a9b165af-a00a-51d3-9680-300b5548e685',
        title: 'Family Budget Services Review',
        slug: '/credit-counseling/family-budget-services-review/',
        date: '2023-01-11T20:15:36.000Z',
        modified: '2023-02-09T19:31:58.000Z',
        excerpt: 'If you are in debt and looking for a way out or to at least get it under control, the team at FBSI has a plan for you.',
        featured_image: {
            source_url: '/media/fbsi-header.png',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 4,
        content: (
            <>
                <h2 id="a-question-about-questions">A question about… Questions?</h2>
                <p>2022 has been the year of financial uncertainty, pause (dramatic effect)… FOR EVERYONE. -from the top 1% all the way down to you and me.</p>
                <p> Things are changing (costs) and adjustments are being made (prices) that affect you and your family one way or another. </p>
                <p>Maybe these changes are good for you, but rest assured what is good for you is probably bad for someone else, and that idea is sometimes referred to as &quot;balance&quot;.</p>
                <p>Since the bad for us accounts for more than 80% of Americans, it is safe to say that we are not in balance. </p>
                <p>A recent survey conducted by Credit Karma/Qualtrics found that 51% of Americans feel that their level of financial knowledge was holding them back.</p>
                <p>Wouldn&rsquo;t it be great to have access to a reliable wealth of financial knowledge?</p>
                <LazyLoadImage src="/media/fbsi-introducing.png" alt="introducing fbsi" />
                <h2 id="introducing-family-budget-services-inc">Introducing Family Budget Services Inc</h2>
                <p>
                    <a href="https://www.ac934intrk.com/3J67C/4QJ4X5/?sub1=fbsi-review&amp;sub2=family-budget-services-inc&amp;sub3=60881" rel="noopener noreferrer" target="_blank">Family Budget Services Inc</a>
                    . or FBSI, is a non-profit organization on a quest to educate consumers and the community for free.
                </p>
                <p>
                    They offer a wide range of services such as credit counseling, debt consolidation and
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/4QJ4X5/?sub1=fbsi-review&amp;sub2=debt-settlement&amp;sub3=60881" rel="noopener noreferrer" target="_blank">debt settlement</a>
                    .
                </p>
                <p>When it comes to debt, every person&rsquo;s financial situation is different. FBSI acknowledges this key element and assigns a personal credit counselor or credit coach to each client that will be there for you throughout the duration of the program.</p>
                <h2 id="fbsi-mission-and-approach">FBSI Mission and Approach:</h2>
                <p>
                    <em>
                        &quot;Our goal is to educate and empower students and families to take control of their debt. We don&rsquo;t want you with us forever! Our diverse team of financial leaders is on a mission to guide you toward financial stability without subtracting from your quality of life. We do this by working with you one-on-one to develop a personalized program for your financial needs and goals to get out of debt and stay out of
                        {' '}
                        <a href="https://www.ac934intrk.com/3J67C/4QJ4X5/?sub1=fbsi-review&amp;sub2=debt&amp;sub3=60881" rel="noopener noreferrer" target="_blank">debt</a>
                        . Throughout your program we assist you as you develop your financial literacy, learn to sustain your budget, and build your credit… so you can live a life you love.&quot;
                    </em>
                </p>
                <h2 id="how-it-works">How it Works</h2>
                <p>FBSI has created a wonderful fast and free quote estimate widget located on the yourFBSI.org link below. Personally, I really appreciate any information upfront so I can get my head around the scope of the problem. </p>
                <p>This tool is helpful, informative and convenient. I&rsquo;m surprised more companies do not have a feature like this. That alone gave me a sense of confidence in FBSI. </p>
                <p>Example of a typical debt situation where you have $10k of debt into the FBSI widget calculator. Results with a quote for paying off debt split into 3 categories.</p>
                <LazyLoadImage src="/media/fbsi-chart.png" alt="chart of typical debt examples" />
                <p>
                    <strong>&quot;On Your Own&quot;</strong>
                    {' '}
                    (indicated by a RED-BOX)
                </p>
                <p>
                    <strong>&quot;With FBSI&quot;</strong>
                    {' '}
                    (Indicated by a YELLOW-BOX)
                </p>
                <p>
                    <strong>&quot;Savings&quot;</strong>
                    {' '}
                    (Indicated by a GREEN-BOX)
                </p>
                <p>RED (on your own):</p>
                <ul>
                    <li>Minimum Monthly Payment: $310.00</li>
                    <li> Total time to pay off: 494 months</li>
                    <li> Total paid in Interest: $38,857.50</li>
                </ul>
                <p> YELLOW (with FBSI): </p>
                <ul>

                    <li> Minimum Monthly Payment: $250.00</li>

                    <li>Total time to pay off: 44 months</li>

                    <li>Total paid in Interest: $962.07</li>
                </ul>
                <p> GREEN (savings):</p>
                <ul>
                    <li> Total Monthly Savings: $60.00</li>

                    <li>Total Time Savings: 450 months</li>

                    <li> Total Interest Savings: $37,895.43</li>
                </ul>
                <h3>Follow these 5 simple steps </h3>
                <ol>

                    <li>
                        <strong>Step 1:</strong>
                        {' '}
                        Go to www.yourFBSI.org
                    </li>
                    {' '}
                    <li>
                        <strong>Step 2:</strong>
                        {' '}
                        Enter Name, Email, and estimated total debt.
                    </li>
                    {' '}
                    <li>
                        <strong>Step 3:</strong>
                        {' '}
                        Click on &quot;Quote&quot;
                    </li>
                    {' '}
                    <li>
                        <strong>Step 4:</strong>
                        {' '}
                        Review FBSI Quote
                    </li>
                    {' '}
                    <li>
                        <strong>Step 5:</strong>
                        {' '}
                        If the quote is satisfactory, then just enter your phone number, best time to call and click &quot;Yes, I want to be Debt Free&quot;
                    </li>
                </ol>
                <LazyLoadImage src="/media/fbsi-what-people-say.png" alt="what people are saying about fbsi" />
                <h2 id="what-people-are-saying-about-fbsi">What People are Saying About FBSI</h2>
                <p>
                    <em>&quot;This letter is to commend the services of FBSI in monitoring my financial climb out of debt. I was kept well informed of my account activity and any time I inquired Ms. Urbina, you responded quickly. You were a tremendous aide in my endeavors. The newsletters also helped to keep my eyes on the prize of a debt-free lifestyle. Thank you so much!&quot; </em>
                    {' '}
                    <br />
                    {' '}
                    <br />
                    {' '}
                    from: BRENDA K.
                    {' '}
                </p>
                <p>
                    <em>&quot;We are so thankful for all of the assistance with our accounts. FBSI has been such a blessing to us. We give you guys an A+ rating. Fantastic, excellent customer service, Kind, understanding, honorable, and helpful. We recommend your company to anyone who needs to get a handle on their debt.&quot;</em>
                    {' '}
                    <br />
                    {' '}
                    <br />
                    {' '}
                    from: JANE D.
                    {' '}
                </p>
                <p>
                    <em>&quot;My experience with FBSI has been a very positive one. I don&rsquo;t know what I would have done if they had not or were unable to help me. I would recommend this program to anyone needing help with their high interest credit cards.&quot;</em>
                    {' '}
                    <br />
                    {' '}
                    <br />
                    {' '}
                    From: MARY E.
                    {' '}
                </p>
                <h2 id="final-thought">Final Thought </h2>
                <p> Family Budget Services Inc. is a one stop shop for all your financial needs.</p>
                <p> If you are in debt and looking for a way out or to at least get it under control, the team at FBSI has a plan for you.</p>
                <p>
                    The quote tool located on the
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/4QJ4X5/?sub1=fbsi-review&amp;sub2=yourfbsi-org&amp;sub3=60881" rel="noopener noreferrer" target="_blank">yourFBSI.org</a>
                    {' '}
                    site is simple and effective at giving the consumer an idea of what could be done in a typical program.
                    {' '}
                </p>
                <p> The team at FBSI is comparable to an all star draft of first round picks. Every member brings almost 2 decades or more of experience in various facets of the financial landscape. </p>
                <p>Testimonials are all focused around the team member that helped and guided these customers through rough times and brought them out the other side better financially. </p>
                <p> But more importantly, they are more informed and better suited to handle the future. FBSI is accredited by the Better Business Bureau (BBB) and has an A+ rating. </p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default FamilyBudgetServicesReviewCreditCounselingPage;
